import { FC } from 'react';

import { Text, Heading } from '@weave/design-system';

import {
  containerStyle,
  imageStyle,
  textContainerStyle,
  titleStyle,
  descriptionStyle,
} from './form-not-found.styles';

interface FormNotFoundProps {
  notFound?: boolean;
  isSubmitted?: boolean;
  isExpired?: boolean;
}

export const FormNotFound: FC<FormNotFoundProps> = ({
  notFound = false,
  isSubmitted = false,
  isExpired,
}) => {
  if (isSubmitted) {
    return (
      <div css={containerStyle}>
        <img src="/images/form-received.svg" alt="form received image" css={imageStyle} />

        <div css={textContainerStyle}>
          <Heading level={2} css={titleStyle}>
            Your form&apos;s trip is complete.
          </Heading>

          <Text css={descriptionStyle}>Thank you.</Text>
        </div>
      </div>
    );
  }

  if (notFound) {
    return (
      <div css={containerStyle}>
        <img
          src="/images/form-does-not-exist.svg"
          alt="form does not exist image"
          css={imageStyle}
        />

        <div css={textContainerStyle}>
          <Heading level={2} css={titleStyle}>
            This form no longer exists.
          </Heading>

          <Text css={descriptionStyle}>
            The form you are looking for doesn&apos;t exist anymore.
          </Text>
        </div>
      </div>
    );
  }

  if (isExpired) {
    return (
      <div css={containerStyle}>
        <img src="/images/form-expired.svg" alt="form expired image" css={imageStyle} />

        <div css={textContainerStyle}>
          <Heading level={2} css={titleStyle}>
            This form is no longer available.
          </Heading>

          <Text css={descriptionStyle}>
            This form link is not valid anymore. If you believe this is an error, please
            get in touch with our office.
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div css={containerStyle}>
      <img
        src="/images/form-unable-to-fetch.svg"
        alt="unable to fetch form image"
        css={imageStyle}
      />

      <div css={textContainerStyle}>
        <Heading level={2} css={titleStyle}>
          We can&apos;t fetch your form.
        </Heading>

        <Text css={descriptionStyle}>We are unable to fetch them right now.</Text>
      </div>
    </div>
  );
};

export default FormNotFound;
