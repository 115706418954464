import { css } from '@emotion/core';
import { theme } from '@weave/theme';

import { mobileScreenMaxWidth } from '@forms-exp/constants';

export const containerStyle = css`
  & > *:not(:last-of-type) {
    margin-bottom: ${theme.spacing(5)};
  }

  .readonly-field-wrapper {
    margin-bottom: ${theme.spacing(2)};
  }

  .datepicker-readonly {
    button {
      display: none;
    }
  }

  .readonly-field-label {
    color: ${theme.colors.text.subdued};
    margin-bottom: ${theme.spacing(1)};
  }

  .rich-text-container {
    p {
      color: ${theme.colors.text.default};
    }
  }

  pre {
    white-space: pre-wrap;
  }

  @media (max-width: ${mobileScreenMaxWidth}) {
    & > *:not(:last-of-type) {
      margin-bottom: ${theme.spacing(4)};
    }
    .readonly-field-wrapper {
      margin-bottom: ${theme.spacing(1)};
    }
  }

  .highlight-option {
    width: max-content;
    background-color: #f8ff00;
  }
`;

export const checkboxStyle = css`
  font-weight: ${theme.font.weight.bold};
`;

export const optionSwitchStyle = css`
  button {
    &[aria-checked='true'] {
      color: ${theme.colors.primary50} !important;
      border-color: ${theme.colors.primary50} !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
`;

export const searchListBoxStyles = css`
  ul {
    max-height: 300px;
  }
`;
