import axios from 'axios';

import { Packet, Form } from '@forms-exp/types';
import { environmentVariables } from '@forms-exp/env';

type ListType = 'packets' | 'forms';

interface PacketsListResponse {
  success: boolean;
  data: Packet[];
}

interface FormsListResponse {
  success: boolean;
  data: Form[];
}

function fetchList(companyId: string, type: 'forms'): Promise<FormsListResponse>;
function fetchList(companyId: string, type: 'packets'): Promise<PacketsListResponse>;
async function fetchList(companyId: string, type: ListType) {
  const url = `${environmentVariables.baseApiUrl}/forms-digital/v1/kiosk/${type}?company_id=${companyId}`;

  try {
    const { data } = await axios.get(url);
    return { success: true, data: data[type] || [] };
  } catch (err) {
    return { success: false, data: [] };
  }
}

export async function fetchKioskForms(companyId: string): Promise<{
  success: boolean;
  data: { packets: Packet[]; forms: Form[] };
}> {
  try {
    const responses = await Promise.all([
      fetchList(companyId, 'packets'),
      fetchList(companyId, 'forms'),
    ]);

    console.log(responses[1].data[0]);

    return {
      success: true,
      data: {
        packets: responses[0].data,
        forms: responses[1].data,
      },
    };
  } catch (err) {
    console.log('Error fetching the forms/packets list', err);
    return { success: false, data: { packets: [], forms: [] } };
  }
}
